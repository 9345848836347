import { z } from "zod";

import type { TAbilities } from "../../../services/src/permissions/types";

export const Roles = z.enum([
  "org:logger",
  "org:apeiron",
  "admin",
  "org:waitlist",
]);
export type TRole = z.infer<typeof Roles>;

export const CurrentUser = z.object({
  userId: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  emailAddress: z.string(),
  organisationId: z.string().uuid(),
  organisationName: z.string(),
  role: Roles,
});

export type TCurrentUser = z.infer<typeof CurrentUser> & {
  abilities: TAbilities;
  /**
   * The group IDs that the user is a member of
   */
  groups: string[];
};

export interface TApeironSession {
  userId: string;
  organisationId: string;
  accessToken: string;
  user: TCurrentUser;
  emailAddress: string;
  clerk: {
    userId: string;
    organisationId: string;
  };
}
