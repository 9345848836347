import type { TRole } from "apeiron-zod/src/user/session";
import { Roles } from "apeiron-zod/src/user/session";

export const hasApeironAccess = (role: TRole) => {
  return role === Roles.enum["org:apeiron"] || role === Roles.enum.admin;
};

export const hasLoggerAccess = (role: TRole) => {
  return role === Roles.enum["org:logger"] || hasApeironAccess(role);
};

export const isOnWaitList = (role: TRole) => {
  return role === Roles.enum["org:waitlist"];
};
